import styled, { keyframes } from "styled-components"

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 5%, 0)
  }

  100% {
    opacity: 1; 
    transform: none; 
  }
`

const Wrap = styled.section`
  margin: 0 auto;
  animation: ${fadeInUp} 0.65s ease both;

  .pattern {
    height: 0;
    width: 40%;
    padding-bottom: 7.5%;
    position: absolute;
    right: 0;

    &.left {
      right: auto;
      left: 0;
      width: 3rem;
      top: 4.5%;
    }

    .xn-pattern {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
`

export default Wrap
