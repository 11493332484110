import styled from "styled-components"
import Arrow from "../../images/dropdown.svg"

const Header = styled.section`
  padding-top: 7.5rem;
  width: 50%;
  margin: 0 auto;
  padding-bottom: 4.5rem;

  h1 {
    font-size: 3rem;
    padding-bottom: 0.75rem;
    letter-spacing: -0.75px;
  }

  li {
    display: inline-block;
    margin-right: 2rem;
    font-size: 0.8rem;
    opacity: 0.5;
  }

  nav {
    opacity: 0.5;
    padding-bottom: 0.5rem;
    a {
      font-size: 0.75rem;
      display: inline-block;
      padding-right: 1.5rem;
      position: relative;
      text-transform: uppercase;
      font-weight: 600;

      &:after {
        content: "";
        position: absolute;
        right: 0.5rem;
        width: 0.5rem;
        height: 0.5rem;
        background-image: url("${Arrow}");
        transform: rotateZ(-90deg);
        background-size: contain;
        top: 2px;
      }

      &:last-child {
        opacity: 1;
        &:after {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 766px) {
    width: calc(100% - 4rem);

    h1 {
      font-size: 2.5rem;
    }
  }
`

export default Header
