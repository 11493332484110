import * as React from "react"
import styled from "styled-components"
import Google from "../../images/badges/google.svg"
import Apple from "../../images/badges/apple.svg"

export const vw = px => {
  return (px / 1280) * 100 + "vw"
}

const Box = styled.div`
  background-color: #00e2c1;
  color: #000;
  border-radius: 4px;
  margin-top: 4rem;
  display: flex;

  @media (max-width: 766px) {
    display: block;
    /* text-align: center; */
  }

  h1 {
    font-family: var(--xn-font-body);
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.7rem;
    letter-spacing: -0.75px;
    position: relative;
    top: -0.5rem;
    padding-bottom: 1.5rem;

    span {
      color: #fff;
      display: block;
    }
  }

  .copy {
    width: 75%;
    padding: 2.5rem;

    @media (max-width: 766px) {
      width: 100%;
    }
  }

  .image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    img {
      object-fit: contain;
      width: 75%;
      height: 75%;
      object-position: bottom;
      margin-right: 1rem;
    }

    @media (max-width: 766px) {
      display: block;

      img {
        margin: 0 auto;
        width: 65%;
        height: 65%;
        position: relative;
        left: -2rem;
      }
    }
  }
`

const Badges = styled.div`
  display: flex;
  padding-top: ${vw(36)};

  a {
    display: block;
    width: ${vw(136)};
    height: ${vw(37)};
    position: relative;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-position: left;
  }

  @media (max-width: 766px) {
    padding-top: 3rem;

    a {
      width: ${vw(400)};
      height: ${vw(108)};
    }
  }
`

const Ele = () => {
  return (
    <Box>
      <div className="copy">
        <h1>
          Start from as little as <span>UGX 10,000 or KES 500</span>
        </h1>
        <span>
          Saving money is an excellent first step toward financial freedom.
          Start your journey today.
        </span>
        <Badges>
          <a
            href="https://play.google.com/store/apps/details?id=com.xeno.investment&showAllReviews=true"
            rel="noreferrer"
            target="_blank"
            className="google"
          >
            <img src={Google} alt="" />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            className="apple"
            href="https://apps.apple.com/ug/app/xeno-investment/id1572436933#?platform=iphone"
          >
            <img src={Apple} alt="" />
          </a>
        </Badges>
      </div>
      <div className="image">
        <img
          src="https://res.cloudinary.com/xeno-technologies/image/upload/v1627963367/app-store-image_qw7fzh.png"
          alt=""
        />
      </div>
    </Box>
  )
}
export default Ele
