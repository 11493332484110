import styled from "styled-components"

const Hero = styled.figure`
  background: #d3d3d3;
  width: 75%;
  margin: 0 auto;
  overflow: hidden;
  height: 36rem;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .xn-socials {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 2;
    padding-top: 0;

    a {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    height: 40vh;
  }
`

export default Hero
