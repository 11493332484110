import React from "react"
import Socials from "../../components/shared/socials"
import Pattern from "../../components/shared/pattern"
import { Link } from "gatsby"
import Body from "./body"
import Hero from "./hero"
import Header from "./header"
import Wrap from "./wrap"
import { graphql } from "gatsby"
import Meta from "../../utilities/seo"
import Cta from "./cta"

export const ArticleQuery = graphql`
  query SingleArticleQuery($uid: String!) {
    prismicArticle(uid: { eq: $uid }) {
      uid
      last_publication_date
      data {
        cover {
          url
          dimensions {
            width
            height
          }
          fluid {
            src
          }
        }
        published
        summary {
          text
        }
        title {
          text
        }
        type
        body {
          ... on PrismicArticleBodyText {
            slice_type
            primary {
              editor_content {
                html
              }
            }
          }
        }
      }
    }
  }
`

const Template = ({ data }) => {
  const node = data.prismicArticle.data
  const uid = data.prismicArticle.uid
  const firstPublished = node.published
  const lastPublished = data.prismicArticle.last_publication_date
  const html = node.body[0].primary.editor_content.html
  const date = new Date(firstPublished)
  const lastDate = new Date(lastPublished)
  return (
    <>
      <Meta
        title={node.title.text}
        image={{
          url: node.cover.url,
          height: node.cover.dimensions.height,
          width: node.cover.dimensions.width,
        }}
        description={node.summary.text}
      />

      <Wrap>
        <div className="pattern right">
          <Pattern type="dot" size="4" />
        </div>
        <div className="pattern left">
          <Pattern type="green" size="4" />
        </div>
        <Header>
          <nav>
            <Link to="/">Learn</Link>
            <Link to={"/articles/" + uid}>{node.title.text}</Link>
          </nav>
          <h1>
            <span>{node.title.text}</span>
          </h1>
          <ul>
            <li>
              <time dateTime={firstPublished ? firstPublished : lastPublished}>
                {firstPublished ? date.toDateString() : lastDate.toDateString()}
              </time>{" "}
            </li>
          </ul>
        </Header>

        <Hero>
          <img src={node.cover.fluid.src} alt={node.title.text} />
          <Socials slug={uid} title={node.title.text} />
        </Hero>

        <Body>
          {html && <div dangerouslySetInnerHTML={{ __html: html }}></div>}
          <Socials slug={uid} title={node.title.text} />
          <Cta />
        </Body>
      </Wrap>
    </>
  )
}

export default Template
